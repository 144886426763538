/* eslint-disable import/newline-after-import */
import { lazy, Suspense } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'

// Fonts
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/noto-sans-mono/400.css'
import '@fontsource/noto-sans-mono/700.css'

// Hooks
import useVersionLog from '../../hooks/useVersionLog'

// Сhakra theme
import theme from '../../theme/theme'

// Redux store
import { store } from '../../redux/store'

// Components
import SentryRoutes from '../../components/sentry-routes/SentryRoutes'

// Pages
import LoaderPage from '../loader-page/LoaderPage'
import useSentryLogging from '../../hooks/useSentryLogging'
const PaymentPageV1 = lazy(() => import('../payment-page-v1/PaymentPageV1'))
const PaymentPageV2 = lazy(() => import('../payment-page-v2/PaymentPageV2'))
const Page404 = lazy(() => import('../page-404/Page404'))
const Success = lazy(() => import('../success/Success'))
const Fail = lazy(() => import('../fail/Fail'))
const OrderClose = lazy(() => import('../order-closed-page/OrderClosedPage'))

export default function Root() {
  localStorage.clear()
  // Log app version
  useVersionLog()

  // Log errors
  useSentryLogging()

  return (
    <ReduxProvider store={store}>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<LoaderPage />}>
          <BrowserRouter>
            <SentryRoutes>
              <Route path="/:uuid" element={<PaymentPageV1 />} />
              <Route path="/v2/:uuid" element={<PaymentPageV2 />} />
              <Route path="/success" element={<Success />} />
              <Route path="/fail" element={<Fail />} />
              <Route path="/order-closed" element={<OrderClose />} />
              <Route path="*" element={<Page404 />} />
            </SentryRoutes>
          </BrowserRouter>
        </Suspense>
      </ChakraProvider>
    </ReduxProvider>
  )
}
